.ag-theme-balham .ag-row-selected {
  background-color: #50bebe;
  border-bottom-color: #50bebe;
  color: #ffffff;
}

.ag-theme-balham .ag-status-bar {
  text-transform: lowercase;
}
.ag-theme-balham .ag-status-bar span::first-letter {
  text-transform: uppercase;
}
.ag-theme-balham .ag-overlay-no-rows-center {
  text-transform: lowercase;
  display: inline-block;
}

.ag-theme-balham .ag-overlay-no-rows-center::first-letter {
  text-transform: uppercase;
}

.cell {
  overflow: hidden;
}
