.ag-cell-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.ag-react-container {
  height: 100%;
}

/* Allow popup dialogs in cell renderers  */
.ag-row {
  z-index: 0;
}
.ag-row-focus {
  z-index: 1;
}
.ag-cell {
  overflow: visible;
}
