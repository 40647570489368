.markdown {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  word-wrap: break-word;
}

.markdown li {
  list-style: initial;
}

.markdown blockquote {
  border-left: 2px solid gray;
  padding-left: 4px;
}

.markdown em {
  font-style: italic;
}
