#use-case-management-table .ag-tool-panel-wrapper {
  width: 320px;
}

#use-case-management-table .ag-react-container {
  width: 100%;
}
#data-grid .ag-tool-panel-wrapper {
  width: 320px;
}

#data-grid .ag-react-container {
  width: 100%;
}
.ag-theme-balham .ag-side-bar .ag-side-buttons .ag-selected button {
  background-color: white;
}
.ag-theme-balham .ag-side-bar .ag-side-buttons {
  display: none;
}
.ag-theme-balham .ag-tool-panel-wrapper {
  border-right: none;
}
.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0 !important;
  padding: 16px 0;
}
.list {
  overflow: auto;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 8px;
}
.title {
  color: #3aa9ae;
}
.button {
  background-color: #3aa9ae !important;
  color: white !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  width: 185px !important;
}
.data-view-button {
  background-color: #3aa9ae !important;
  color: white !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
}
.button-root {
  margin-top: 16px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.tags {
  display: flex;
  flex-direction: column;
}
.chip {
  margin-right: 8px;
  margin-bottom: 8px;
  color: #68777b;
}
.outlined {
  border: solid 1px #68777b !important;
}
.description {
  white-space: normal;
}
.primary {
  color: #ced1d4;
  font-size: 12px !important;
}
.secondary {
  color: #000 !important;
}
/* 
#use-case-management-table  .ag-header-container .ag-header-row:nth-child(1)
{
color: #fff;
background-color: #3AA9AE;
} 
*/
