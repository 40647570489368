.container {
  flex: 1;
  overflow: overlay;
  display: flex;
  height: 100vh;
}
.grid {
  height: 100%;
}
.logo {
  width: 350px;
}
.form {
  width: 380px;
  max-width: "100%";
}
.left {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 35px;
}
.leftContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 483px;
}
.right {
  background-color: #3aa9ae;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  padding: 20px 60px;
}
.rightContent {
  height: 483px;
  padding-top: 25px;
}

.link {
  cursor: pointer;
}
.button {
  color: #ffffff;
  box-shadow: none !important;
  margin-bottom: 16px !important;
}
.footer {
  display: flex;
  flex-direction: column;
}
.signUpFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confirmSignUpFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerSecondaryContent {
  color: #828282;
  font-size: 14px;
  margin-top: 24px;
  text-align: center;
}
.formField {
  margin-bottom: 24px;
}
.label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #152939;
}
.input {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #152939;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-sizing: border-box;
}

.forgetPassword {
  color: #828282;
  font-size: 12px;
  margin-bottom: 24px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.singInLink {
  color: #828282;
  font-size: 12px;
}
.appTitle {
  font-weight: 300 !important;
  /* font-size: 35px !important; */
}
.environment {
  font-size: 27px !important;
  text-transform: uppercase;
  font-weight: bold !important;
}
.appDescription {
  margin-top: 24px !important;
  font-size: 30px !important;
  font-weight: 300 !important;
}
